import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment-mini";
import { useSelector } from "react-redux";

import Image from "components/Image";
import A from "components/A";
import Overlay from "components/partials/ImageOverlay";
import Tag from "components/partials/Tag";
import FadeIn from "components/partials/FadeIn";
import RatioWrapper from "components/partials/RatioWrapper";
import Arrow from "icons/ArrowUp";

export default function EventThumbnail(props) {
  const [time, setTime] = useState();
  const [eventDate, setEventDate] = useState();
  const [festivalStart, setFestivalStart] = useState();
  const [festivalEnd, setFestivalEnd] = useState();
  let lang = useSelector((state) => state.lang);
  const { item } = props;
  let href = item.uri;

  useEffect(() => {
    //manipulate date string and get event time/date for item
    if (props.type === "event") {
      setTime(
        item.eventData.eventStart.slice(0, item.eventData.eventStart.length - 3)
      );
      setEventDate(
        moment(item.eventData.eventDate).format(
          lang === "no" ? "DD.MM.YY" : "MM.DD.YY"
        )
      );
    } else if (props.type === "festival") {
      setFestivalStart(
        moment(item.festivalData.dateStart).format(
          lang === "no" ? "DD.MM.YY" : "MM.DD.YY"
        )
      );
      setFestivalEnd(
        moment(item.festivalData.dateEnd).format(
          lang === "no" ? "DD.MM.YY" : "MM.DD.YY"
        )
      );
    }
  }, [props.type, item, lang]);

  return (
    <EventItem>
      <FadeIn>
        {props.type === "event" ? (
          <Top>
            <Time>{props.showTime ? time : eventDate}</Time>
            <Venue>
              <StyledArrow />
              <span>{item.eventData.chapter && item.eventData.chapter.toUpperCase()}</span>
            </Venue>
          </Top>
        ) : (
          <Top>
            <Time>{festivalStart + " - " + festivalEnd}</Time>
            <Venue>
              <StyledArrow />
              <span>{item.festivalData.chapter && item.festivalData.chapter.toUpperCase()}</span>
            </Venue>
          </Top>
        )}
        <A href={href}>
          <StyledOverlay>
            <RatioWrapper ratio="4:3">
              <StyledImage
                src={item?.thumbnailData?.featuredImage?.mediaItemUrl ?? 'default-image.jpg'}
              />
            </RatioWrapper>
          </StyledOverlay>
          <p>{item.title}</p>
        </A>
        {item.metaTags.nodes.length ? (
          <Bottom>
            {item.metaTags.nodes.map((tag, i) => {
              return <Tag tag={tag} key={i} />;
            })}
          </Bottom>
        ) : null}
      </FadeIn>
    </EventItem>
  );
}

const EventItem = styled.div`
  text-align: left;
  margin-bottom: 64px;

  p {
    ${(props) => props.theme.fonts.h5}
  }
`;

const StyledOverlay = styled(Overlay)`
  margin-bottom: 16px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Time = styled.span`
  ${(props) => props.theme.fonts.h8}
`;

const Venue = styled.span`
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;

  span {
    ${(props) => props.theme.fonts.h8}
  }
`;

const StyledArrow = styled(Arrow)`
  display: inline;
  width: 10px;
  margin-right: 6px;
  margin-top: 3px;
`;

const StyledImage = styled(Image)`
  max-width: none;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Bottom = styled.div`
  display: flex;
`;
